import React from 'react'
import injectSheet from 'react-jss'
// import classNames from 'classnames'
import IntrestedPost from './InterestedPost'

interface Props {
  data: HQ.Post[]
  classes: any
}

class InterestedBlock extends React.Component<Props> {
  render() {
    const { data, classes: c } = this.props
    return (
      <div>
        <h4 className={c.Title}>You may be interested:</h4>
        {data.map(item => (
          <IntrestedPost key={item.id} data={item} />
        ))}
      </div>
    )
  }
}

const styles = {
  Title: {
    fontWeight: 500,
    fontSize: '36px',
    lineHeight: '42px',
    color: '#333',
    padding: '54px 0',
    margin: 0,
    '@media (max-width: 1024px)': {
      fontSize: '24px',
      lineHeight: '28px',
      padding: '48px 0',
    },
  },
}

export default injectSheet(styles)(InterestedBlock)
