import React from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'

interface Props {
  url: string
  classes: any
  className: string
}

class SocialBLock extends React.Component<Props> {
  render() {
    const { url, classes: c, className } = this.props
    return (
      <div className={classNames(c.SocialContainer, className)}>
        <FacebookShareButton url={url}>
          <FacebookIcon className={c.SocialIcons} />
        </FacebookShareButton>
        <LinkedinShareButton url={url}>
          <LinkedinIcon className={c.SocialIcons} />
        </LinkedinShareButton>
        <TwitterShareButton url={url}>
          <TwitterIcon className={c.SocialIcons} />
        </TwitterShareButton>
      </div>
    )
  }
}

const styles = {
  SocialContainer: {
    position: 'sticky',
    top: '100px',
    marginLeft: '-110px',
    float: 'left',
    background: '#fff',
    borderRadius: '4px',
    padding: '8px',
    '& > div': {
      padding: '8px',
      width: '40px',
      height: '40px',
      '&:focus': {
        outline: 'none',
      },
    },
    '&.mobile': {
      display: 'none',
    },
    '@media (max-width: 1024px)': {
      '&.desktop': {
        display: 'none',
      },
      '&.mobile': {
        position: 'relative',
        float: 'right',
        margin: 0,
        top: 'auto',
        background: 'none',
        display: 'flex',
        marginTop: '-12px',
        '& > div': {
          padding: '0px 4px',
          width: '24px',
          height: '24px',
        },
      },
    },
  },
  SocialIcons: {
    width: '40px',
    height: '40px',
    borderRadius: '8px',
    '@media (max-width: 1024px)': {
      width: '24px',
      height: '24px',
    },
  },
}

export default injectSheet(styles)(SocialBLock)
