import React from 'react'
import injectSheet from 'react-jss'
// import classNames from 'classnames'

interface Props {
  data: HQ.Author
  date: string
  classes: any
}

class AuthorBlock extends React.Component<Props> {
  render() {
    const {
      data: {
        avatar: {
          fixed: { src: avatarUrl },
        },
        name: authorName,
      },
      date,
      classes: c,
    } = this.props
    return (
      <div className={c.BlogAuthor}>
        <img alt={authorName} src={avatarUrl} />
        <div className={c.BlogAuthorText}>
          <p className="author-name">{authorName}</p>
          <p className="author-date">{date}</p>
        </div>
      </div>
    )
  }
}

const styles = {
  BlogAuthor: {
    borderRadius: '4px',
    backgroundColor: '#fff',
    padding: '24px 0',
    maxWidth: '30%',
    minWidth: '240px',
    '& > img': {
      float: 'left',
      marginRight: '15px',
    },

    '@media (max-width: 1024px)': {
      maxWidth: '100%',
    },
  },
  BlogAuthorText: {
    '& > p': {
      fontWeight: 500,
      margin: '4px 0 0 0 ',
    },
    '& > .author-name': {
      color: '#333',
      fontSize: '16px',
      lineHeight: '19px',
    },
    '& > .author-date': {
      color: '#999',
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
}

export default injectSheet(styles)(AuthorBlock)
