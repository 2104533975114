import React from 'react'
import { graphql } from 'gatsby'
import injectSheet from 'react-jss'
import Layout from '../layout'
import AuthorBlock from './AuthorBlock'
import InterestedBlock from './InterestedBlock'
import Page from '../Page/Page'
import SocialBlock from './SocialBlock'

interface Props {
  data: {
    datoCmsPost: HQ.Post
    allDatoCmsPost: HQ.Posts
  }
  location: Location
  classes: any
}

class BlogPostPage extends React.Component<Props> {
  render() {
    const {
      data: {
        datoCmsPost: {
          bodyNode: {
            childMarkdownRemark: { html },
          },
          title,
          description,
          category: {
            title: categoryTitle,
            color: { hex },
          },
          author,
          cover: { url },
          meta: { publishedAt },
          featured,
        },
        allDatoCmsPost: { nodes: interestedPosts },
      },
      location,
      classes: c,
    } = this.props
    return (
      <Layout location={location}>
        <Page>
          <div className={c.BlogContainer}>
            <SocialBlock className="desktop" url={location.href} />
            <div>
              <h1 className={c.BlogTitle}>{title}</h1>
              <div className={c.BlogCategory}>
                <span style={{ backgroundColor: hex }}>{categoryTitle}</span>
                {featured && <span style={{ backgroundColor: '#55B69E' }}>Featured</span>}
                <SocialBlock className="mobile" url={location.href} />
              </div>
              <AuthorBlock data={author} date={publishedAt} />
              <div className={c.BlogContent}>
                <p className={c.BlogDescription}>{description}</p>
                <img className={c.BlogCover} alt={title} src={url} />
                <div className={c.BlogBody} dangerouslySetInnerHTML={{ __html: html }} />
              </div>
              <InterestedBlock data={interestedPosts} />
            </div>
          </div>
        </Page>
      </Layout>
    )
  }
}

export const query = graphql`
  query BlogOverviewPage($id: String!) {
    datoCmsPost(id: { eq: $id }) {
      title
      description
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
      category {
        title
        color {
          hex
        }
      }
      cover {
        url
      }
      author {
        name
        avatar {
          fixed(height: 48, width: 48) {
            src
          }
        }
      }
      meta {
        publishedAt(formatString: "MMM D, YYYY")
      }
      featured
    }
    allDatoCmsPost(limit: 2, filter: { id: { ne: $id } }) {
      nodes {
        id
        description
        title
        slug
        cover {
          url
        }
        category {
          title
          color {
            hex
          }
        }
      }
    }
  }
`

const styles = {
  BlogContainer: {
    maxWidth: '735px',
    margin: '0 auto 50px auto',
    position: 'relative',
  },
  BlogTitle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '48px',
    lineHeight: '56px',
    color: '#333333',
    margin: 0,
    '@media (max-width: 1024px)': {
      fontSize: '36px',
      lineHeight: '42px',
    },
  },
  BlogContent: {
    borderRadius: '4px',
    backgroundColor: '#fff',
    padding: '40px',
    marginBottom: '24px',
    '@media (max-width: 1024px)': {
      padding: '24px',
    },
  },
  BlogBody: {
    fontSize: '21px',
    lineHeight: '140%',
    fontWeight: 300,
    color: '#30343f',
    fontFamily: 'Spectral',

    '& > img': {
      maxWidth: 'calc(100% + 80px)',
      margin: '0 -40px',
    },
  },
  BlogDescription: {
    color: '#333',
    fontSize: '18px',
    lineHeight: '1.6',
  },
  BlogCover: {
    width: 'calc(100% + 80px)',
    margin: '0 -40px',
    padding: '40px 0',
    '@media (max-width: 1024px)': {
      padding: '20px 0',
    },
  },
  BlogCategory: {
    '& > span': {
      padding: '4px 10px',
      borderRadius: '6px',
      marginRight: '16px',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      textTransform: 'uppercase',
      color: '#fff',
    },
    marginTop: '24px',
  },
}

export default injectSheet(styles)(BlogPostPage)
