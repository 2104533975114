import React from 'react'
import { Link } from 'gatsby'
import injectSheet from 'react-jss'
// import classNames from 'classnames'

interface Props {
  data: HQ.Post
  classes: any
}

class InterestedBlock extends React.Component<Props> {
  render() {
    const { data, classes: c } = this.props
    return (
      <Link to={`blog/${data.slug}`} className={c.PostLink}>
        <div className={c.PostBlock}>
          <div className={c.PostContent}>
            <h5>{data.title}</h5>
            <p>{data.description}</p>
          </div>
          <div className={c.PostBlockImage}>
            <span className={c.ImageCategory} style={{ backgroundColor: data.category.color.hex }}>
              {data.category.title}
            </span>
          </div>
        </div>
      </Link>
    )
  }
}

const styles = {
  PostLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  PostBlock: {
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.15)',
    marginBottom: '32px',
    display: 'flex',
    overflow: 'hidden',
    '@media (max-width: 1024px)': {
      flexDirection: 'column-reverse',
    },
  },
  PostBlockImage: {
    backgroundImage: (props: any) => `url(${props.data.cover.url})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    position: 'relative',
    flex: 2,
    minHeight: '340px',
    '@media (max-width: 1024px)': {
      minHeight: '250px',
    },
  },
  ImageCategory: {
    position: 'absolute',
    right: '33px',
    top: '26px',
    padding: '5px 10px',
    textTransform: 'uppercase',
    borderRadius: '6px',
    color: '#fff',
    '@media (max-width: 1024px)': {
      top: '24px',
      left: '24px',
      right: 'auto',
    },
  },
  PostContent: {
    padding: '32px',
    flex: 3,
    '@media (max-width: 1024px)': {
      padding: '24px',
    },

    '& > h5': {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '140%',
      margin: '0 0 16px 0 ',
      '@media (max-width: 1024px)': {
        fontSize: '24px',
        lineHeight: '28px',
      },
    },
    '& > p': {
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: '160%',
      color: '#999',
      paddingLeft: '5px',
    },
  },
}

export default injectSheet(styles)(InterestedBlock)
